import React, { createContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AuthorizedRoutes from './AuthorizedRoutes';
import LoginPage from './pages/LoginPage/LoginPage';
import MainPage from './pages/MainPage/MainPage';
import PasswordResetLoginPage from './pages/PasswordResetLogin/PasswordResetLoginPage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import SignUpPage from './pages/SignUpPage/SignUpPage';
import SettingsPage from './pages/SettingsPage/SettingsPage';
import { ThemeProvider } from '@mui/material/styles';
import RulesPage from './pages/Rules/Rules';
import { ShopPage } from './pages/ShopPage/ShopPage';
import { ServerPage } from './pages/ServerPage/ServerPage';
import PasswordResetPage from './pages/PasswordReset/PasswordReset';
import StartPlay from './pages/LetsPlay/LetsPlay';
import { RedirectToAdmin } from './components/RedirectToAdmin';
import Page404 from './components/Page404';
import { RedirectToDiscord } from './components/RedirectToDiscord';
import DiscordSync from './pages/DiscordSync/DiscordSync';
import Page500 from './components/Page500';
import LauncherWelcome from './pages/LauncherWelcome/Launcher';
import AdminUsersPage from './pages/AdminPage/AdminUsersPage/AdminUsersPage';
import AdminServersPage from './pages/AdminPage/AdminServersPage/AdminServersPage';
import AdminAuditPage from './pages/AdminPage/AdminAuditPage/AdminAuditPage';
import AdminGroupsPage from './pages/AdminPage/AdminGroupsPage/AdminGroupsPage';
import AdminAnalyticsPage from './pages/AdminPage/AdminAnalyticsPage/AdminAnalyticsPage';
import AdminViolationsPage from './pages/AdminPage/AdminBansPage/AdminViolationsPage';
import { AuthState, IAuthContext, initialAuthContext, loadSelfFromBackendAndUpdateContext } from './stores/authContext';
import IUserSelfInfo from './types/IUserSelfInfo';
import Navigation from './components/Navigation/Navigation';
import Footer from './pages/MainPage/Footer';
import createTheme from '@mui/material/styles/createTheme';
import {
  backgroundColorOfSeverity,
  defaultNotificationContext,
  INotificationContext, textColorOfSeverity,
} from './stores/notificationContext';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import EmailConfirmation from './pages/SignUpPage/EmailConfirmation';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { baseAPI_V2 } from './baseAPIV2';
import AdminUserInfoPage from './pages/AdminPage/AdminUsersPage/AdminUserInfo';
import AdminGroupInfoPage from './pages/AdminPage/AdminGroupsPage/AdminGroupInfo';
import AdminGroupAttributesPage from './pages/AdminPage/AdminGroupsPage/AdminGroupAttributes';


export const AuthContext = createContext(initialAuthContext());
export const NotificationContext = createContext(defaultNotificationContext());


export const starshineTheme = createTheme({
  palette: {
    secondary: {
      main: '#ffd700',
    },
    mode: 'dark',
  },
});


const AppRouter = () => {

  const [authState, setAuthState] = useState<AuthState>(AuthState.LOADING);
  const [authUser, setAuthUser] = useState<IUserSelfInfo | null>(null);
  const [authError, setAuthError] = useState<Error | null>(null);

  let authContext: IAuthContext = {
    state: authState,
    user: authUser,
    error: authError,
    setState: setAuthState,
    setUser: setAuthUser,
    setError: setAuthError,
  };

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const [userHint, setUserHint] = useState<string | null>(null);
  const [timeoutMS, setTimeoutMs] = useState<number>(5000);
  const [severity, setSeverity] = useState<'error' | 'warning' | 'info' | 'success'>('info');

  let notificationContext: INotificationContext = {
    open: open,
    userHint: userHint,
    message: message,
    timeoutMs: timeoutMS,
    severity: severity,
    setOpen: setOpen,
    setUserHint: setUserHint,
    setMessage: setMessage,
    setTimeoutMs: setTimeoutMs,
    setSeverity: setSeverity,
  };

  useEffect(() => {
    baseAPI_V2.interceptors.response.use(undefined, (error) => {
      if (error.response.status === 401 && !error.request.responseURL.includes("@me")) {
        loadSelfFromBackendAndUpdateContext(authContext)
      }
      return Promise.reject(error);
    });
    loadSelfFromBackendAndUpdateContext(authContext);
  }, []);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <AuthContext.Provider value={authContext}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <NotificationContext.Provider value={notificationContext}>
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          autoHideDuration={timeoutMS}
          onClose={handleClose}
          message={message}
        >
          <SnackbarContent
            style={{ backgroundColor: backgroundColorOfSeverity(severity), color: textColorOfSeverity(severity), maxWidth: "400px" }}
            message={<div>
              <div id="client-snackbar">{message}</div>
              { userHint !== null ? <Divider variant="fullWidth" component="div" style={{margin: "6px 0"}} /> : null }
              { userHint !== null ? <span style={{color: "#ddd", fontSize: "12px"}} id="client-snackbar-hint">{userHint}</span> : null }
              { userHint !== null ? <Divider variant="fullWidth" component="div" style={{margin: "6px 0 0 0"}} /> : null }
            </div>}
            action={action}
          />
        </Snackbar>
        <ThemeProvider theme={starshineTheme}>
          <BrowserRouter>
            <Navigation />
            <Routes>
              <Route path='/password-reset' element={<PasswordResetLoginPage />} />
              <Route path='/password-reset-request/:user_id/:token' element={<PasswordResetPage />} />
              <Route index element={<MainPage />} />
              <Route path='/rules' element={<RulesPage />} />
              <Route path='/launcher' element={<LauncherWelcome />} />
              <Route path='/play' element={<StartPlay />} />
              <Route path='/error_500' element={<Page500 />} />
              <Route path='/servers/:id' element={<ServerPage />} />
              <Route path='/admin' element={<RedirectToAdmin />} />
              <Route path='/accounts/confirm/:jwt' element={<EmailConfirmation />} />
              <Route path='/' element={<AuthorizedRoutes />}>
                <Route path='/accounts/discord/sync/start' element={<RedirectToDiscord />} />
                <Route path='/accounts/discord/sync' element={<DiscordSync />} />
                <Route path='/profiles/@self/:page' element={<SettingsPage />} />
                <Route path='/profiles/:id' element={<ProfilePage />} />
                <Route path='/shop' element={<ShopPage />} />
                <Route index element={<MainPage />} />
                <Route path='/admin-panel/users' element={<AdminUsersPage />} />
                <Route path='/admin-panel/users/:id/details' element={<AdminUserInfoPage />} />
                <Route path='/admin-panel/servers' element={<AdminServersPage />} />
                <Route path='/admin-panel/audit' element={<AdminAuditPage />} />
                <Route path='/admin-panel/groups' element={<AdminGroupsPage />} />
                <Route path='/admin-panel/groups/:id/details' element={<AdminGroupInfoPage />} />
                <Route path='/admin-panel/groups/:id/attributes' element={<AdminGroupAttributesPage />} />
                <Route path='/admin-panel/analytics' element={<AdminAnalyticsPage />} />
                <Route path='/admin-panel/violations' element={<AdminViolationsPage />} />
                <Route path='*' element={<Page404 />} />
              </Route>
              <Route path='/login' element={<LoginPage />} />
              <Route path='/sign-up' element={<SignUpPage />} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </ThemeProvider>
      </NotificationContext.Provider>
      </LocalizationProvider>
    </AuthContext.Provider>
  );
};

export default AppRouter;

import React, { useContext, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CircularProgress from '@mui/material/CircularProgress';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import './Navigation.css';
import UserMenu from './UserMenu';
import '../../global.css';
import { useTranslation } from 'react-i18next';
import BalanceModal from './BalanceModal';
import { StarCoinIcon } from '../StarCoinIcon';
import { AuthContext } from '../../AppRouter';
import { AuthState } from '../../stores/authContext';

const Navigation = () => {
  const [balanceModalIsOpen, setBalanceModalIsOpen] = useState(false);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState<HTMLElement | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {state, user} = useContext(AuthContext);

  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  if (location.pathname.startsWith('/profiles/@self')) return null;
  if (location.pathname.startsWith('/launcher')) return null;
  let activeMainPageStyle = window.location.pathname === '/' ? 'underline' : 'none';
  let activeDonatePageStyle = window.location.pathname === '/shop' ? 'underline' : 'none';
  let activeRulesStyle = window.location.pathname === '/rules' ? 'underline' : 'none';

  return (
      <AppBar position='static'>
        <Container maxWidth='xl'>
          <Toolbar disableGutters>
            <BalanceModal open={balanceModalIsOpen} setOpen={setBalanceModalIsOpen}/>
            <Typography
              variant='h6'
              noWrap
              component='div'
              sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
            >
              <Link id='nav-logo' to='/'>
                <img src='/SP_.jpg' alt='logo' />
                <span style={{ lineHeight: '2em', marginLeft: '0.5em' }}>STARSHINE PROJECT</span>
              </Link>
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleOpenNavMenu}
                color='inherit'
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id='menu-appbar'
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <MenuItem onClick={() => {navigate('/'); handleCloseNavMenu();}}>
                  <Typography className="navigation-button-mobile" textAlign='center' style={{ textDecoration: activeMainPageStyle}}>
                    {t('nav-button-main').toUpperCase()}
                  </Typography>
                </MenuItem>
                <MenuItem onClick={() => {navigate('/shop'); handleCloseNavMenu();}}>
                  <Typography className="navigation-button-mobile" textAlign='center' style={{ textDecoration: activeDonatePageStyle}}>
                    {t('nav-button-donate').toUpperCase()}
                  </Typography>
                </MenuItem>
                <MenuItem onClick={() => {navigate('/rules'); handleCloseNavMenu();}}>
                  <Typography className="navigation-button-mobile" textAlign='center' style={{ textDecoration: activeRulesStyle}}>
                    {t('nav-button-rules').toUpperCase()}
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
            <Typography
              id="brand"
              variant='h6'
              noWrap
              component='div'
              sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
              style={{justifyContent: "flex-end"}}
            >
              {state === AuthState.AUTHORIZED && user !== null ? <div id="balance-container" onClick={() => {setBalanceModalIsOpen(true)}} >
                  <span id="user-balance" >{user.balance}</span>
                  <StarCoinIcon/>
              </div> : null}

              <Tooltip title='Open settings'>
                <span onClick={(event) => {setAnchorElUser(event.currentTarget)}} id="avatar-button">
                  <IconButton  sx={{ p: 0, mr: 0.5 }}>
                    <div>
                      {(state == AuthState.LOADING) ? <CircularProgress key='loading' /> : null }
                      {(state == AuthState.AUTHORIZED && user !== null) ? <Avatar alt={user.username} src={user.getAvatar()}/> : null }
                      {(state == AuthState.UNAUTHORIZED) ? <Avatar alt="? ?" /> : null }
                      {(state == AuthState.ERROR) ? <Avatar alt="? ?" /> : null }
                    </div>
                  </IconButton>
                  <KeyboardArrowDownIcon style={{verticalAlign: "middle"}}/>
                </span>
              </Tooltip>
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', alignItems: 'center' } }}>
              <Button
                onClick={() => {
                  handleCloseNavMenu();
                  navigate('/', { state: { from: location } });
                }}
                className='navigation-button-desktop'
                style={{ textDecoration: activeMainPageStyle}}
              >
                {t('nav-button-main').toUpperCase()}
              </Button>
              <Button
                onClick={() => {
                  handleCloseNavMenu();
                  navigate('/shop', { state: { from: location } });
                }}
                className='navigation-button-desktop'
                style={{ textDecoration: activeDonatePageStyle }}
              >
                {t('nav-button-donate').toUpperCase()}
              </Button>
              <Button
                onClick={() => {
                  handleCloseNavMenu();
                  navigate('/rules', { state: { from: location } });
                }}
                className='navigation-button-desktop'
                style={{ textDecoration: activeRulesStyle }}
              >
                {t('nav-button-rules').toUpperCase()}
              </Button>
              {user !== null && user.hasPermission("web.admin-panel") ?
                <Button
                  onClick={() => {
                    handleCloseNavMenu();
                    navigate('/admin-panel/users', { state: { from: location } });
                  }}
                  className='navigation-button-desktop'
                >
                  <span style={{ color: "#ff254e"}}>{t('Admin panel').toUpperCase()}</span>
                </Button>
                : null}
            </Box>
            <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex', alignItems: 'center' } }}>
              {state === AuthState.AUTHORIZED && user !== null ? <div id="balance-container" onClick={() => {setBalanceModalIsOpen(true)}}>
                <span id="user-balance">{user.balance}</span>
                <StarCoinIcon/>
              </div> : null}

              <Tooltip title='Open settings'>
                <span onClick={(event) => {setAnchorElUser(event.currentTarget)}} id="avatar-button">
                  <IconButton sx={{ p: 0, mr: 0.5 }}>
                      {(state == AuthState.LOADING) ? <CircularProgress size={20} /> : null }
                      {(state == AuthState.AUTHORIZED && user !== null) ? <Avatar alt={user.username} src={user.getAvatar()}/> : null }
                      {(state == AuthState.UNAUTHORIZED) ? <span style={{fontSize: "14px", height: "20px", lineHeight: "20px"}}>{t('Login').toUpperCase()}</span> : null }
                      {(state == AuthState.ERROR) ? <Avatar alt="? ?" /> : null }
                  </IconButton>
                  <KeyboardArrowDownIcon style={{verticalAlign: "middle"}}/>
                </span>
              </Tooltip>
              <UserMenu anchorElUser={anchorElUser} setAnchorElUser={setAnchorElUser}/>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
  );
};

export default Navigation;
